// @flow

import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { FormattedMessageSanitized } from '../../FormattedMessageSanitized'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../../components/basic/Grid'
import Button from '../../../components/basic/Button'
import SubpageHeroTeaser from '../../../components/SubpageHeroTeaser'
import Contacts from './../Contacts'

import messages from './PrivacyPage.lang'
import baseStyles from './PrivacyPage.scss'

type Props = {}

/* eslint-disable max-len */
const imageDesktop = (
  <StaticImage
    src="../../../images/heroteaser/imprint_heroteaser_bg_desktop.jpg"
    alt=""
    objectFit="cover"
    className="image"
  />
)
/* eslint-enable max-len */

const PrivacyPage = ({ styles }: StyleProps) => {
  const { formatMessage } = useIntl()
  const [analyticsOff, setAnalyticsOffstate] = useState(false)

  const toggle = () => {
    setAnalyticsOffstate(prev => !prev)
  }

  return (
    <div className={styles.root}>
      <SubpageHeroTeaser title={formatMessage(messages.pageTitle)} image={imageDesktop} imgAlt="office gallery" />
      <Container fluid>
        <Contacts />
        <div className={styles.text}>
          <FormattedMessageSanitized tagName="div" {...messages.text} />
        </div>
        <div className={styles.analyticsCheck}>
          <FormattedMessageSanitized {...messages.analyticsCheck} />
          <Button
            styles={[styles.btn, analyticsOff && styles['is-off']]}
            tag="a"
            id="gaSwitchOff"
            href="javascript:gaOptout()"
            onClick={toggle}
            color="primary"
            iconRight={analyticsOff ? 'icon-success-1' : 'icon-right'}
          >
            {formatMessage(messages.turnOff)}
          </Button>
        </div>
        <div className={styles.text}>
          <FormattedMessageSanitized tagName="div" {...messages.text2} />
        </div>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(PrivacyPage)
